/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
/*===============================================
VARIABLES GENERALES
=================================================*/

const burguer = document.querySelector('#burguer');
const menu = document.querySelector('#menu-mobile li:nth-child(2) > ul');


$(document).ready(function(){
    $('.tooltipForm').tooltip({
        show: null,
        position: {
            my: "left top",
            at: "left bottom"
        },
        open: function( event, ui ) {
            ui.tooltip.animate({ top: ui.tooltip.position().top + 10 }, "fast" );
        }
    });
    ExecuteEvents();
});


//FUNCTION THAT ENABLE THE EVENTS
function ExecuteEvents(){
    burguer.addEventListener('click', menuBurguer);
    carouselSwiper();
    showModalNewsletter();
}

// FUNCTIONS

/**
 * Función para hacer funcionar nuestro carousel con swiper
 */
function carouselSwiper(){
    let swiper = new Swiper('.mySwiper', {
        slidesPerView: 1,
        spaceBetween: 70,
        grabCursor: true,
        loop: true,
        speed: 300,
        breakpoints:{
            991:{
                slidesPerView: 3
            }
        },
        navigation: {
            nextEl: '.swiper-button-next', // Selector del botón de siguiente
            prevEl: '.swiper-button-prev' // Selector del botón de anterior
        }
    });

    let swiperCertificates = new Swiper('.mySwiperCertificates', {
        slidesPerView: 1,
        spaceBetween: 20,
        grabCursor: true,
        loop: true,
        speed: 300,
        breakpoints:{
            768: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            1024:{
                slidesPerView: 2,
                spaceBetween: 20
            },
            1400: {
                slidesPerView: 2,
                spaceBetween: 200
            }
        },
        navigation: {
            nextEl: '.swiper-button-next', // Selector del botón de siguiente
            prevEl: '.swiper-button-prev' // Selector del botón de anterior
        }
    });

    let swiperClubNegocios = new Swiper('.mySwiperClubNegocios', {
        slidesPerView: 1,
        spaceBetween: 20,
        grabCursor: true,
        loop: true,
        speed: 300,
        breakpoints:{
            768: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            1024:{
                slidesPerView: 2,
                spaceBetween: 20
            },
            1400: {
                slidesPerView: 3,
                spaceBetween: 70
            }
        },
        navigation: {
            nextEl: '.swiper-button-next', // Selector del botón de siguiente
            prevEl: '.swiper-button-prev' // Selector del botón de anterior
        }
    });

    let swiperTestimonial = new Swiper('.mySwiperTestimonial', {
        slidesPerView: 1,
        spaceBetween: 30,
        centeredSlides: true,
        loop: true,
        direction:'vertical',
        autoplay: {
            delay: 4000,
            disableOnInteraction: false
        },
        breakpoints:{
            991:{
                slidesPerView: 1
            }
        },
        allowTouchMove: false
    });

}

function menuBurguer(){
    burguer.classList.toggle('active');
    menu.classList.toggle('open');
}

/**
 * author: ISC. OSWALDO ALVAREZ JUÁREZ
 * email: oswaldo.aj29@outlook.com
 * description: método que muestra un modal de suscripción unicamente la primera vez que un usuario ingresa a la página
 */
function showModalNewsletter(){
    removePromo = function() {
        return localStorage.welcome = true;
    };

    $("#welcomeModal").on("hidden.bs.modal", removePromo);

    if (localStorage.welcome === 'false' || !localStorage.welcome || typeof localStorage.welcome === 'undefined') {
        $('#welcomeModal').modal('show');
    }
}

/**
 * Funcion para mostrar el gif de carga
 */
function loadingGifFunction() {
    let loadingOverlay = $('#loading-overlay');
    let loadingGif = $('#loading-gif');
    console.log('loading gif');

    // Muestra el spinner antes de que la página cambie
    loadingOverlay.show();
    loadingGif.show();

    // Ocultar el spinner cuando la página ha cargado
    $(window).on('load', function() {
        loadingOverlay.hide();
        loadingGif.hide();
    });
}

$('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
  if (!$(this).next().hasClass('show')) {
    $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
  }
  var $subMenu = $(this).next('.dropdown-menu');
  $subMenu.toggleClass('show');


  $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
    $('.dropdown-submenu .show').removeClass('show');
  });


  return false;
});


$('#contactForm').submit(function(event){
    event.preventDefault();
    let link = $(this).attr('action');
    let count_error = 0;
    let formData = new FormData(document.getElementById("contactForm"));
    
    if($('#contact-name').val().length === 0){
        count_error++;
        showMessagesContactForm('Debe de escribir su nombre para poder solicitar la información.');
        $('#contact-name').addClass('border-red');
        $('#contact-name').focus();
    }else{
        $('#contact-name').removeClass('border-red');
    }
    if($('#contact-email').val().length === 0 && emailIsValid($('#contact-email').val()) === false){
        count_error++;
        showMessagesContactForm('Ingrese un email válido.');
        $('#contact-email').addClass('border-red');
        $('#contact-email').focus();
    }else{
        $('#contact-email').removeClass('border-red');
    }
    
    if($('#contact-cellphone').val().length > 0){
        if($('#contact-cellphone').val().length < 10 || $('#contact-cellphone').val().length > 10 && isNaN($('#contact-cellphone').val())){
            count_error++;
            showMessagesContactForm('Debe ingresar un número de celular válido.');
            $('#contact-cellphone').addClass('border-red');
            $('#contact-cellphone').focus();
        }else{
            $('#contact-cellphone').removeClass('border-red');
        }
    }else{
        $('#contact-cellphone').removeClass('border-red');
    }
    
    if($('#contact-message').val().length === 0){
        count_error++;
        showMessagesContactForm('Debe Escribir su mensaje para que podamos ayudarlo.');
        $('#contact-message').addClass('border-red');
        $('#contact-message').focus();
    }else{
        $('#contact-message').removeClass('border-red');
    }
    
    
    if (count_error === 0) {
        $('.form-contact-message').text('');
        $('.form-contact-message').removeClass('alert-danger');
        $('.form-contact-message').removeClass('warning-border-message-form');
        $('.form-contact-message').removeClass('hidden');
        $.ajax({
            url: link,
            type: "POST",
            dataType: "json",
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: function(){
                $('.form-contact-message').html('<div class="sk-chase"><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div>'+
                        '<div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div></div>');
            },
            success: function(data){
                $('.form-contact-message').addClass('alert-success success-border-message-form');
                $('.form-contact-message').html(data.messages);
                $('#contact-name').val('');
                $('#contact-email').val('');
                $('#contact-cellphone').val('');
                $('#contact-message').val('');
            },
            error: function(data){
               $('.form-contact-message').addClass('alert-danger warning-border-message-form');
               $('.form-contact-message').html(data.responseJSON.messages);
            }
        });
    }
});


$('#infoMembershipForm').submit(function(event){
    event.preventDefault();
    let link = $(this).attr('action');
    let count_error = 0;
    let formData = new FormData(document.getElementById("infoMembershipForm"));
    
    if($('#txtModalInfoMemberName').val().length === 0){
        count_error++;
        showMessageInfoMember('Debe escribir su nombre.', 'txtModalInfoMemberName');
    }else{
        $('#txtModalInfoMemberName').removeClass('border-red');
    }
    
    if($('#txtModalInfoMemberCity').val().length === 0){
        count_error++;
        showMessageInfoMember('Debe escribir su ciudad de residencia.', 'txtModalInfoMemberCity');
    }else{
        $('#txtModalInfoMemberCity').removeClass('border-red');
    }
    
    if($('#txtModalInfoMemberEmail').val().length === 0 && emailIsValid($('#txtModalInfoMemberEmail').val()) === false){
        count_error++;
        showMessageInfoMember('Ingrese una dirección de correo electrónico válida.', 'txtModalInfoMemberEmail');
    }else{
        $('#txtModalInfoMemberEmail').removeClass('border-red');
    }
    
    if($('#txtModalInfoMemberPhone').val().length > 0){
        if($('#txtModalInfoMemberPhone').val().length < 10 || $('#txtModalInfoMemberPhone').val().length > 10 && isNaN($('#txtModalInfoMemberPhone').val())){
            count_error++;
            showMessageInfoMember('Debe ingresar un número de teléfono válido.', 'txtModalInfoMemberPhone');
        }else{
            $('#txtModalInfoMemberPhone').removeClass('border-red');
        }
    }else{
        $('#txtModalInfoMemberPhone').removeClass('border-red');
    }
    
    if($('#txtModalInfoMemberCellphone').val().length > 0){
        if($('#txtModalInfoMemberCellphone').val().length < 10 || $('#txtModalInfoMemberCellphone').val().length > 10 && isNaN($('#txtModalInfoMemberCellphone').val())){
            count_error++;
            showMessageInfoMember('Debe ingresar un número de celular válido.', 'txtModalInfoMemberCellphone');
        }else{
            $('#txtModalInfoMemberCellphone').removeClass('border-red');
        }
    }else{
        $('#txtModalInfoMemberCellphone').removeClass('border-red');
    }

    if($('#txtModalInfoMemberPhone').val().length === 0 && $('#txtModalInfoMemberCellphone').val().length === 0){
        count_error++;
        showMessageInfoMember('Debe ingresar almenos 1 número telefónico.', 'txtModalInfoMemberCellphone');
    }else{
        $('#txtModalInfoMemberPhone').removeClass('border-red');
    }
    
    if(parseInt($('#slctModalInfoMemberCard').val()) === 0){
        count_error++;
        showMessageInfoMember('Por favor indique si cuenta con tarjeta de crédito.', 'slctModalInfoMemberCard');
    }else{
        $('#slctModalInfoMemberCard').removeClass('border-red');
    }
    
    if(parseInt($('#slctModalInfoMemberTrip').val()) === 0){
        count_error++;
        showMessageInfoMember('Por favor indique si planea viajar próximamente.', 'slctModalInfoMemberTrip');
    }else{
        $('#slctModalInfoMemberTrip').removeClass('border-red');
    }
    
    if($('#txtModalInfoMemberMessage').val().length === 0 ){
        count_error++;
        showMessageInfoMember('Por favor déjenos un mensaje con sus dudas.', 'txtModalInfoMemberMessage');
    }else{
        $('#txtModalInfoMemberMessage').removeClass('border-red');
    }
    
    if (count_error === 0) {
        $('.form-contact-infomember-message').text('');
        $('.form-contact-infomember-message').removeClass('alert-danger');
        $('.form-contact-infomember-message').removeClass('warning-border-message-form');
        $('.form-contact-infomember-message').removeClass('hidden');
        $.ajax({
            url: link,
            type: "POST",
            dataType: "json",
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: function(){
                $('.form-contact-infomember-message').html('<div class="sk-chase"><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div>'+
                        '<div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div></div>');
            },
            success: function(data){
                $('.form-contact-infomember-message').addClass('alert-success success-border-message-form');
                $('.form-contact-infomember-message').html(data.messages);
                $('#txtModalInfoMemberName').val('');
                $('#txtModalInfoMemberCity').val('');
                $('#txtModalInfoMemberEmail').val('');
                $('#txtModalInfoMemberPhone').val('');
                $('#txtModalInfoMemberCellphone').val('');
                $('#txtModalInfoMemberMessage').val('');
            },
            error: function(data){
               $('.form-contact-infomember-message').addClass('alert-danger warning-border-message-form');
               $('.form-contact-infomember-message').html(data.responseJSON.messages);
            }
        });
    }
});

$('.carousel-directory-traveler').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
    {
        breakpoint: 1024,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
        }
    },
    {
        breakpoint: 600,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
    ]
});


$('#form-subscribe').submit(function(event){
    event.preventDefault();
    let link = $(this).attr('action');
    let count_error = 0;
    let formData = new FormData(document.getElementById("form-subscribe"));
    //console.log(formData);
    
    $.ajax({
        url: link,
        type: "POST",
        dataType: "json",
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        beforeSend: function(){
            $('.message-subscribe').removeClass('alert-danger warning-border-message-form');
            $('.message-subscribe').removeClass('alert-success success-border-message-form');
            $('.message-subscribe').html('<div class="sk-chase"><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div>'+
              '<div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div></div>');
        },
        success: function(data){
            $('.message-subscribe').removeClass('alert-danger warning-border-message-form');
            $('.message-subscribe').addClass('alert-success success-border-message-form');
            $('.message-subscribe').html(data.messages);
            $('#recipient-name').val('');
            $('#recipient-email').val('');
            //console.log('success: ' + data.messages);
            setTimeout(function() {
                $('.message-subscribe').html('');
                $('.message-subscribe').removeClass('alert-success success-border-message-form');
            }, 5000);
            return false;
        },
        error: function(xhr, status, error){
            $('.message-subscribe').removeClass('alert-success success-border-message-form');
            $('.message-subscribe').addClass('alert-danger warning-border-message-form');
            $('.message-subscribe').html('Error: ' + xhr.responseJSON.messages);
            //console.log('error: ' + error);
            //console.log('xhr: ' + xhr.responseText);  
            setTimeout(function() {
                $('.message-subscribe').html('');
                $('.message-subscribe').removeClass('alert-danger warning-border-message-form');
            }, 5000);
            return false;                        
        }
    });
});

$('#form-subscribe-modal').submit(function(event){
    event.preventDefault();
    let link = $(this).attr('action');
    let count_error = 0;
    let formData = new FormData(document.getElementById("form-subscribe-modal"));
    //console.log(formData);
    
    $.ajax({
        url: link,
        type: "POST",
        dataType: "json",
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        beforeSend: function(){
            $('.message-response-modal').removeClass('alert-danger warning-border-message-form');
            $('.message-response-modal').removeClass('alert-success success-border-message-form');
            $('.message-response-modal').html('<div class="sk-chase"><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div>'+
              '<div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div></div>');
        },
        success: function(data){
            $('.message-response-modal').removeClass('alert-danger warning-border-message-form');
            $('.message-response-modal').addClass('alert-success success-border-message-form');
            $('.message-response-modal').html(data.messages);
            $('#recipient-email').val('');
            //console.log('success: ' + data.messages);
            setTimeout(function() {
                $('.message-response-modal').html('');
                $('.message-response-modal').removeClass('alert-success success-border-message-form');
            }, 5000);
            return false;
        },
        error: function(xhr, status, error){
            $('.message-response-modal').removeClass('alert-success success-border-message-form');
            $('.message-response-modal').addClass('alert-danger warning-border-message-form');
            $('.message-response-modal').html('Error: ' + xhr.responseJSON.messages);
            //console.log('error: ' + error);
            //console.log('xhr: ' + xhr.responseText);  
            setTimeout(function() {
                $('.message-response-modal').html('');
                $('.message-response-modal').removeClass('alert-danger warning-border-message-form');
            }, 5000);
            return false;                        
        }
    });
});


$('#searchDestination').change(function(){
    var actualVal = $('#searchDestination').val();
    var arrayAfrica = [
                       [
                        'Kenia',
                        'Mauricio',
                        'Marruecos, Central',
                        'Sudáfrica y Mozambique, playas',
                        'Sudáfrica, Ciudad del Cabo',
                        'Sudáfrica, Campiña y safari',
                        'Sudáfrica, Ruta Jardín',
                        'Gambia',
                        'Túnez'
                       ],
                       [
                        'DestinationForm_1_1_1',
                        'DestinationForm_1_1_2',
                        'DestinationForm_1_1_3', 
                        'DestinationForm_1_1_4',
                        'DestinationForm_1_1_5',
                        'DestinationForm_1_1_6',
                        'DestinationForm_1_1_7',
                        'DestinationForm_1_1_8',
                        'DestinationForm_1_1_9'
                       ]
                      ];
                   
    var arrayAmericaSur = [
                           [
                            'Buenos Aires, Argentina',
                            'Córdoba y San Luis, Argentina',
                            'Entre Ríos, Argentina',
                            'Cataratas de Iguazú, Argentina',
                            'Mar Del Plata y Pinamar, Argentina',
                            'Patagonia y Cuyo, Argentina',
                            'Costa de Patagonia, Argentina',
                            'Tierra del Fuego, Argentina',
                            'Bolivia',
                            'Brasil, Bahía',
                            'Brasil, Paraíba',
                            'Río de Janeiro, Brasil',
                            'Brasil, Río Grande do Norte',
                            'Brasil, Rio Grande do Sul',
                            'Santa Catarina, Brasil',
                            'Brazil, Sao Paulo State, Inland',
                            'Chile, Lake Rapel',
                            'Chile, Lake Region',
                            'Pucón, Chile',
                            'Viña del Mar, Chile',
                            'Cali, Colombia',
                            'Cartagena y Santa Marta, Colombia',
                            'Colombia, Eje Cafetero',
                            'Colombia, Córdoba y Sucre',
                            'Ecuador',
                            'Perú',
                            'Perú, Cuzco y el Valle Sagrado',
                            'Uruguay, Colonia del Sacramento',
                            'Uruguay, Montevideo',
                            'Punta del Este, Uruguay',
                            'Andes, Venezuela',
                            'Costa central, Venezuela',
                            ],
                            [
                            'DestinationForm_3_2_1',
                            'DestinationForm_3_2_2',
                            'DestinationForm_3_2_3', 
                            'DestinationForm_3_2_4',
                            'DestinationForm_3_2_5',
                            'DestinationForm_3_2_6',
                            'DestinationForm_3_2_7',
                            'DestinationForm_3_2_8',
                            'DestinationForm_3_2_9',
                            'DestinationForm_3_2_10',
                            'DestinationForm_3_2_11',
                            'DestinationForm_3_2_12',
                            'DestinationForm_3_2_13', 
                            'DestinationForm_3_2_14',
                            'DestinationForm_3_2_15',
                            'DestinationForm_3_2_16',
                            'DestinationForm_3_2_17',
                            'DestinationForm_3_2_18',
                            'DestinationForm_3_2_19',
                            'DestinationForm_3_2_20',
                            'DestinationForm_3_2_21',
                            'DestinationForm_3_2_22',
                            'DestinationForm_3_2_23', 
                            'DestinationForm_3_2_24',
                            'DestinationForm_3_2_25',
                            'DestinationForm_3_2_26',
                            'DestinationForm_3_2_27',
                            'DestinationForm_3_2_28',
                            'DestinationForm_3_2_29',
                            'DestinationForm_3_2_30',
                            'DestinationForm_3_2_31',
                            'DestinationForm_3_2_32'
                           ]
                          ];
                          
    var arrayAsia = [
                     [
                        'Bahía de Bengala, Bangladesh',
                        'Provincia de Shanghai, China',
                        'Bali, Indonesia',
                        'Islas Bintán y Batam, Indonesia',
                        'Playas de Malasia',
                        'Kuala Lumpur, Malasia',
                        'Sarawak, Malasia',
                        'Aklan, Filipinas',
                        'Filipinas, Cebú e Isla de Mactán',
                        'Bangkok, Tailandia',
                        'Pattaya, Tailandia',
                        'Phuket, Tailandia'
                     ],
                     [
                      'DestinationForm_1_2_1',
                      'DestinationForm_1_2_2',
                      'DestinationForm_1_2_3',
                      'DestinationForm_1_2_4',
                      'DestinationForm_1_2_5',
                      'DestinationForm_1_2_6',
                      'DestinationForm_1_2_7',
                      'DestinationForm_1_2_8',
                      'DestinationForm_1_2_9',
                      'DestinationForm_1_2_10',
                      'DestinationForm_1_2_11',
                      'DestinationForm_1_2_12'
                     ]
                    ];
    
    var arrayAustralia = [
                          [
                            'Australia Central, Nueva Gales del Sur',
                            'Australia, Nueva Gales del Sur, Sydney',
                            'Australia, Queensland, Costa de Oro',
                            'Australia, Queensland, Sunshine Coast',
                            'Australia, Australia del Sur',
                            'Australia, Victoria',
                            'Nueva Zelanda, Paihia',
                            'Nueva Zelanda, Queenstown',
                            'Nueva Zelanda, Taupo',
                            'Nueva Zelanda, Wanaka'
                          ],
                          [
                           'DestinationForm_1_3_1',
                           'DestinationForm_1_3_2',
                           'DestinationForm_1_3_3',
                           'DestinationForm_1_3_4',
                           'DestinationForm_1_3_5',
                           'DestinationForm_1_3_6',
                           'DestinationForm_1_3_7',
                           'DestinationForm_1_3_8',
                           'DestinationForm_1_3_9',
                           'DestinationForm_1_3_10'
                          ]
                         ];
                         
    var arrayCanada = [
                       [
                        'Columbia Británica, Central',
                        'Columbia Británica, Oriental',
                        'Columbia Británica, Sun Peaks',
                        'Columbia Británica, Whistler'
                       ],
                       [
                        'DestinationForm_1_4_1',
                        'DestinationForm_1_4_2',
                        'DestinationForm_1_4_3',
                        'DestinationForm_1_4_4'
                       ]
                      ];
    
    var arrayCentroAme = [
                          [
                            'Belice',
                            'Costa Rica, Guanacaste',
                            'Costa Rica, Other Areas',
                            'Guatemala'
                          ],
                          [
                           'DestinationForm_2_2_1',
                           'DestinationForm_2_2_2',
                           'DestinationForm_2_2_3'  ,
                           'DestinationForm_2_2_4'
                          ]
                         ];
    
    var arrayEuropa = [
                       [
                        'Andorra',
                        'Austria, los Alpes',
                        'Austria, Viena y Salzburgo',
                        'Croacia, Dalmacia',
                        'Chipre',
                        'Inglaterra, centro',
                        'Inglaterra, Devon y Cornwall',
                        'Inglaterra, Distrito de los Lagos',
                        'Inglaterra, costa sur',
                        'Finlandia, Central',
                        'Finlandia, Espoo y Tampere',
                        'Francia, Área de Disneyland',
                        'Francia, París',
                        'Francia, Riviera',
                        'Alemania, Central',
                        'Grecia, Creta y Corfú',
                        'Irlanda',
                        'Italia, Florencia y Toscana',
                        'Italia, Montañas',
                        'Italia, norte y centro',
                        'Italia, Roma',
                        'Italia, Cerdeña',
                        'Italia, Sicilia',
                        'Italia, Sud',
                        'Malta, Islas de Malta y Gozo',
                        'Noruega',
                        'Portugal, Algarve',
                        'Portugal, Costa Oeste',
                        'Escocia y Northumbria',
                        'Escocia, Montañas',
                        'España, Aragón y Pirineos',
                        'España, Islas Baleares',
                        'España, Barcelona y Costa Brava',
                        'España, Canarias, Fuerteventura',
                        'España, Canarias, Gran Canaria',
                        'España, Canarias, Lanzarote',
                        'España, Canarias, Tenerife',
                        'España, Costa Blanca y Costa de Almería',
                        'España, Costa Dorada y Costa del Azahar',
                        'España, Costa del Sol',
                        'Suiza, Montañas',
                        'Turquía, Costa Sur y Oeste',
                        'Gales'
                       ],
                       [
                        'DestinationForm_2_3_1',
                        'DestinationForm_2_3_2',
                        'DestinationForm_2_3_3',
                        'DestinationForm_2_3_4',
                        'DestinationForm_2_3_5',
                        'DestinationForm_2_3_6',
                        'DestinationForm_2_3_7',
                        'DestinationForm_2_3_8',
                        'DestinationForm_2_3_9',
                        'DestinationForm_2_3_10',
                        'DestinationForm_2_3_11',
                        'DestinationForm_2_3_12',
                        'DestinationForm_2_3_13',
                        'DestinationForm_2_3_14',
                        'DestinationForm_2_3_15',
                        'DestinationForm_2_3_16',
                        'DestinationForm_2_3_17',
                        'DestinationForm_2_3_18',
                        'DestinationForm_2_3_19',
                        'DestinationForm_2_3_20',
                        'DestinationForm_2_3_21',
                        'DestinationForm_2_3_22',
                        'DestinationForm_2_3_23',
                        'DestinationForm_2_3_24',
                        'DestinationForm_2_3_25',
                        'DestinationForm_2_3_26',
                        'DestinationForm_2_3_27',
                        'DestinationForm_2_3_28',
                        'DestinationForm_2_3_29',
                        'DestinationForm_2_3_30',
                        'DestinationForm_2_3_31',
                        'DestinationForm_2_3_32',
                        'DestinationForm_2_3_33',
                        'DestinationForm_2_3_34',
                        'DestinationForm_2_3_35',
                        'DestinationForm_2_3_36',
                        'DestinationForm_2_3_37',
                        'DestinationForm_2_3_38',
                        'DestinationForm_2_3_39',
                        'DestinationForm_2_3_40',
                        'DestinationForm_2_3_41',
                        'DestinationForm_2_3_42',
                        'DestinationForm_2_3_43'
                       ]
                      ];
    
    var arrayEua = [
                    [
                        'Alabama, Costa',
                        'Arizona, Lake Havasu',
                        'Arizona, Área de Phoenix',
                        'Arkansas, Ozarks',
                        'California y Nevada, Lake Tahoe',
                        'California, Anaheim',
                        'California, lago Big Bear',
                        'California, Kirkwood',
                        'California, Bosques Nacionales del Norte',
                        'California, Palm Springs y Palm Desert',
                        'California, Área de San Diego',
                        'Colorado, Breckenridge',
                        'Colorado, Steamboat Springs',
                        'Colorado, Vail, Avon y Beaver Creek',
                        'Colorado, Winter Park y Silver Creek',
                        'Florida, Cocoa Beach',
                        'Florida, Área de Daytona Beach',
                        'Florida, Costa de Fort Lauderdale',
                        'Florida, Fort Myers',
                        'Florida, Key West',
                        'Florida, Cayos Bajos',
                        'Florida, Miami',
                        'Florida, Miami Beach',
                        'Florida, Orlando',
                        'Florida, Panhandle',
                        'Florida, Sarasota y Englewood',
                        'Florida, San Agustín',
                        'Florida, Bahía de Tampa',
                        'Florida, Cayos Altos',
                        'Florida, Costa del Tesoro/Vero',
                        'Georgia, Inland Areas',
                        'Hawái, Isla Grande',
                        'Hawai, Kauai',
                        'Hawai, Maui',
                        'Hawai, Molokai',
                        'Hawai, Oahu',
                        'Hilton Head Island y Georgia, costa',
                        'Idaho',
                        'Illinois',
                        'Indiana',
                        'Luisiana, Nueva Orleans',
                        'Maine, Central',
                        'Maine, costa',
                        'Maine, Oeste',
                        'Maryland, Western',
                        'Massachusetts, Berkshires',
                        'Massachusetts, Cabo Cod',
                        'Massachusetts, Martha\'s Vineyard y Nantucket',
                        'Minnesota',
                        'Misisipi, Biloxi',
                        'Misuri, Branson',
                        'Misuri, Lake Ozark',
                        'Montana, Fairmont',
                        'Montana, Lakeside',
                        'Nevada, Las Vegas',
                        'Nevada, Mesquite',
                        'Nevada, Reno',
                        'Centro de New Hampshire',
                        'New Hampshire, lagos Winnipesaukee y Winnisquam',
                        'Nueva Jersey, Atlantic City',
                        'Nueva Jersey, Vernon Valley',
                        'Nuevo México',
                        'Nueva York, Catskills',
                        'Carolina del Norte, Banner Elk y Boone',
                        'Oregón, Central',
                        'Pensilvania, Poconos',
                        'Rhode Island, Newport y Block Island',
                        'Carolina del Sur, Área de Murrells Inlet',
                        'Carolina del Sur, Área de Myrtle Beach',
                        'Tennessee, Gatlinburg',
                        'Texas, Bandera',
                        'Texas, Central',
                        'Texas, Noreste',
                        'Texas, South Padre Island',
                        'Utah, Norte',
                        'Utah, Park City y Snowbird',
                        'Utah, Salt Lake City',
                        'Utah, Sur',
                        'Virginia, Shenandoah',
                        'Virginia, Virginia Beach',
                        'Virginia, Williamsburg',
                        'Washington, Glacier',
                        'Washington, Puget Sound',
                        'Virginia Occidental',
                        'Wisconsin, Wisconsin Dells',
                        'Wyoming',
                    ],
                    [
                     'DestinationForm_3_3_1',
                     'DestinationForm_3_3_2',
                     'DestinationForm_3_3_3',
                     'DestinationForm_3_3_4',
                     'DestinationForm_3_3_5',
                     'DestinationForm_3_3_6',
                     'DestinationForm_3_3_7',
                     'DestinationForm_3_3_8',
                     'DestinationForm_3_3_9',
                     'DestinationForm_3_3_10',
                     'DestinationForm_3_3_11',
                     'DestinationForm_3_3_12',
                     'DestinationForm_3_3_13',
                     'DestinationForm_3_3_14',
                     'DestinationForm_3_3_15',
                     'DestinationForm_3_3_16',
                     'DestinationForm_3_3_17',
                     'DestinationForm_3_3_18',
                     'DestinationForm_3_3_19',
                     'DestinationForm_3_3_20',
                     'DestinationForm_3_3_21',
                     'DestinationForm_3_3_22',
                     'DestinationForm_3_3_23',
                     'DestinationForm_3_3_24',
                     'DestinationForm_3_3_25',
                     'DestinationForm_3_3_26',
                     'DestinationForm_3_3_27',
                     'DestinationForm_3_3_28',
                     'DestinationForm_3_3_29',
                     'DestinationForm_3_3_30',
                     'DestinationForm_3_3_31',
                     'DestinationForm_3_3_32',
                     'DestinationForm_3_3_33',
                     'DestinationForm_3_3_34',
                     'DestinationForm_3_3_35',
                     'DestinationForm_3_3_36',
                     'DestinationForm_3_3_37',
                     'DestinationForm_3_3_38',
                     'DestinationForm_3_3_39',
                     'DestinationForm_3_3_40',
                     'DestinationForm_3_3_41',
                     'DestinationForm_3_3_42',
                     'DestinationForm_3_3_43',
                     'DestinationForm_3_3_44',
                     'DestinationForm_3_3_45',
                     'DestinationForm_3_3_46',
                     'DestinationForm_3_3_47',
                     'DestinationForm_3_3_48',
                     'DestinationForm_3_3_49',
                     'DestinationForm_3_3_50',
                     'DestinationForm_3_3_51',
                     'DestinationForm_3_3_52',
                     'DestinationForm_3_3_53',
                     'DestinationForm_3_3_54',
                     'DestinationForm_3_3_55',
                     'DestinationForm_3_3_56',
                     'DestinationForm_3_3_57',
                     'DestinationForm_3_3_58',
                     'DestinationForm_3_3_59',
                     'DestinationForm_3_3_60',
                     'DestinationForm_3_3_61',
                     'DestinationForm_3_3_62',
                     'DestinationForm_3_3_63',
                     'DestinationForm_3_3_64',
                     'DestinationForm_3_3_65',
                     'DestinationForm_3_3_66',
                     'DestinationForm_3_3_67',
                     'DestinationForm_3_3_68',
                     'DestinationForm_3_3_69',
                     'DestinationForm_3_3_70',
                     'DestinationForm_3_3_71',
                     'DestinationForm_3_3_72',
                     'DestinationForm_3_3_73',
                     'DestinationForm_3_3_74',
                     'DestinationForm_3_3_75',
                     'DestinationForm_3_3_76',
                     'DestinationForm_3_3_77',
                     'DestinationForm_3_3_78',
                     'DestinationForm_3_3_79',
                     'DestinationForm_3_3_80',
                     'DestinationForm_3_3_81',
                     'DestinationForm_3_3_82',
                     'DestinationForm_3_3_83',
                     'DestinationForm_3_3_84',
                     'DestinationForm_3_3_85',
                     'DestinationForm_3_3_86'
                    ]
                   ];
    
    var arrayCaribe = [
                       [
                        'Antigua',
                        'Aruba',
                        'Bahamas, Exuma',
                        'Bahamas, Freeport',
                        'Bahamas, Nassau',
                        'Barbados',
                        'Bermuda',
                        'Bonaire',
                        'Islas Caimán, Gran Caimán',
                        'Curazao',
                        'República Dominicana, Costa Oriental',
                        'República Dominicana, Puerto Plata',
                        'República Dominicana, Costa Sur',
                        'Jamaica',
                        'St. Kitts y Nevis',
                        'Santa Lucía',
                        'St. Maarten y St. Martín',
                        'Trinidad & Tobago',
                        'Islas Turcas y Caicos',
                        'Islas Vírgenes de los EE.UU., St. Thomas',
                        'Venezuela, Isla Margarita'
                       ],
                       [
                        'DestinationForm_2_1_1',
                        'DestinationForm_2_1_2',
                        'DestinationForm_2_1_3',
                        'DestinationForm_2_1_4',
                        'DestinationForm_2_1_5',
                        'DestinationForm_2_1_6',
                        'DestinationForm_2_1_7',
                        'DestinationForm_2_1_8',
                        'DestinationForm_2_1_9',
                        'DestinationForm_2_1_10',
                        'DestinationForm_2_1_11',
                        'DestinationForm_2_1_12',
                        'DestinationForm_2_1_13',
                        'DestinationForm_2_1_14',
                        'DestinationForm_2_1_15',
                        'DestinationForm_2_1_16',
                        'DestinationForm_2_1_17',
                        'DestinationForm_2_1_18',
                        'DestinationForm_2_1_19',
                        'DestinationForm_2_1_20',
                        'DestinationForm_2_1_21'
                       ]
                      ];
    
    var arrayMexico = [
                       [
                        'México, Acapulco',
                        'México, Cancún',
                        'México, Central',
                        'México, Chiapas',
                        'México, Ixtapa',
                        'México, Loreto',
                        'México, Mazatlán',
                        'México, Mérida',
                        'México, Ciudad de México',
                        'México, Nuevo Vallarta y Riviera Nayarit',
                        'Puerto Peñasco, México',
                        'México, Puerto Vallarta',
                        'México, Riviera Maya',
                        'México, Bahía de San Carlos',
                        'México, Baja Sur'
                       ],
                       [
                        'DestinationForm_2_4_1',
                        'DestinationForm_2_4_2',
                        'DestinationForm_2_4_3',
                        'DestinationForm_2_4_4',
                        'DestinationForm_2_4_5',
                        'DestinationForm_2_4_6',
                        'DestinationForm_2_4_7',
                        'DestinationForm_2_4_8',
                        'DestinationForm_2_4_9',
                        'DestinationForm_2_4_10',
                        'DestinationForm_2_4_11',
                        'DestinationForm_2_4_12',
                        'DestinationForm_2_4_13',
                        'DestinationForm_2_4_14',
                        'DestinationForm_2_4_15'
                       ]
                      ];
    
    var arrayOriente = [
                        [
                         "Egipto, Costa Norte",
                         "Egipto, Mar Rojo",
                         "Emiratos Árabes Unidos"
                        ],
                        [
                         'DestinationForm_3_1_1',
                         'DestinationForm_3_1_2',
                         'DestinationForm_3_1_3'
                        ]
                       ];
    
    
    switch(actualVal){
        //Ninguna opcion seleccionada
        case '0':
            $('#searchOption').find('option').remove();
            $('#searchOption').append('<option value="0">Seleccione una opción...</option>');
            break;
        //AFRICA
        case '1':
            $('#searchOption').find('option').remove();
            $('#searchOption').append('<option value="0">Seleccione una opción...</option>');
            for(var x=0; x<arrayAfrica[0].length; x++){
                $('#searchOption').append('<option value="'+arrayAfrica[1][x]+'">'+arrayAfrica[0][x]+'</option');
            }
            break;
        //AMERICA DEL SUR
        case '2':
            $('#searchOption').find('option').remove();
            $('#searchOption').append('<option value="0">Seleccione una opción...</option>');
            for(var x=0; x<arrayAmericaSur[0].length; x++){
                $('#searchOption').append('<option value="'+arrayAmericaSur[1][x]+'">'+arrayAmericaSur[0][x]+'</option');
            }
            break;
        //ASIA
        case '3':
            $('#searchOption').find('option').remove();
            $('#searchOption').append('<option value="0">Seleccione una opción...</option>');
            for(var x=0; x<arrayAsia[0].length; x++){
                $('#searchOption').append('<option value="'+arrayAsia[1][x]+'">'+arrayAsia[0][x]+'</option');
            }
            break;
        //AUSTRALIA Y NUEVA ZELANDA
        case '4':
            $('#searchOption').find('option').remove();
            $('#searchOption').append('<option value="0">Seleccione una opción...</option>');
            for(var x=0; x<arrayAustralia[0].length; x++){
                $('#searchOption').append('<option value="'+arrayAustralia[1][x]+'">'+arrayAustralia[0][x]+'</option');
            }
            break;
        //CANADÁ
        case'5':
            $('#searchOption').find('option').remove();
            $('#searchOption').append('<option value="0">Seleccione una opción...</option>');
            for(var x=0; x<arrayCanada[0].length; x++){
                $('#searchOption').append('<option value="'+arrayCanada[1][x]+'">'+arrayCanada[0][x]+'</option');
            }
            break;
        //CENTROAMERICA
        case'6':
            $('#searchOption').find('option').remove();
            $('#searchOption').append('<option value="0">Seleccione una opción...</option>');
            for(var x=0; x<arrayCentroAme[0].length; x++){
                $('#searchOption').append('<option value="'+arrayCentroAme[1][x]+'">'+arrayCentroAme[0][x]+'</option');
            }
            break;
        //EUROPA
        case'7':
            $('#searchOption').find('option').remove();
            $('#searchOption').append('<option value="0">Seleccione una opción...</option>');
            for(var x=0; x<arrayEuropa[0].length; x++){
                $('#searchOption').append('<option value="'+arrayEuropa[1][x]+'">'+arrayEuropa[0][x]+'</option');
            }
            break;
        //ESTADOS UNIDOS
        case'8':
            $('#searchOption').find('option').remove();
            $('#searchOption').append('<option value="0">Seleccione una opción...</option>');
            for(var x=0; x<arrayEua[0].length; x++){
                $('#searchOption').append('<option value="'+arrayEua[1][x]+'">'+arrayEua[0][x]+'</option');
            }
            break;
        //ISLAS DEL CARIBE Y ATLANTICO
        case'9':
            $('#searchOption').find('option').remove();
            $('#searchOption').append('<option value="0">Seleccione una opción...</option>');
            for(var x=0; x<arrayCaribe[0].length; x++){
                $('#searchOption').append('<option value="'+arrayCaribe[1][x]+'">'+arrayCaribe[0][x]+'</option');
            }
            break;
        //MÉXICO
        case'10':
            $('#searchOption').find('option').remove();
            $('#searchOption').append('<option value="0">Seleccione una opción...</option>');
            for(var x=0; x<arrayMexico[0].length; x++){
                $('#searchOption').append('<option value="'+arrayMexico[1][x]+'">'+arrayMexico[0][x]+'</option');
            }
            break;
        //ORIENTE MEDIO
        case'11':
            $('#searchOption').find('option').remove();
            $('#searchOption').append('<option value="0">Seleccione una opción...</option>');
            for(var x=0; x<arrayOriente[0].length; x++){
                $('#searchOption').append('<option value="'+arrayOriente[1][x]+'">'+arrayOriente[0][x]+'</option');
            }
            break;
    }
});



function emailIsValid (email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function showMessagesContactForm(text){
    $('.form-contact-message').text(text);
    $('.form-contact-message').removeClass('alert-success');
    $('.form-contact-message').removeClass('success-border-message-form');
    $('.form-contact-message').addClass('alert-danger warning-border-message-form');
    $('.form-contact-message').removeClass('hidden');
}

function showMessageInfoMember(text, inputControl){
    $('.form-contact-infomember-message').text(text);
    $('.form-contact-infomember-message').removeClass('alert-success');
    $('.form-contact-infomember-message').removeClass('success-border-message-form');
    $('.form-contact-infomember-message').addClass('alert-danger warning-border-message-form');
    $('.form-contact-infomember-message').removeClass('hidden');
    
    $('#'+inputControl).addClass('border-red');
    $('#'+inputControl).focus();
}